import React from "react"
import CategoryCard from "components/categoryCard"
import Layout from "components/layout"
import { graphql } from "gatsby"

const category = "pcparts";
const path = "/category/pcparts/";

export default function Category(props) {
  // collect images of pcparts category
  const pages = props.data.site.siteMetadata.pages.filter(page => page.category === category);
  pages.forEach(page => {
    page.image = page.image || "cover/defaultthumb.png"
    const name = page.image.split('.')[0].replace(/-/g,'');
    page.name = name.substring(name.lastIndexOf('/') + 1);
  });
  return (
    <Layout {...props} path={path}>
      <h1>パーツ別パソコンの選び方の記事一覧</h1>
      {pages.map(page => <CategoryCard {...props} {...page}/>)}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        pages {
          title
          description
          image
          category
          path
          noIndex
        }
      }
    }
    cpu: file(relativePath: { eq: "cover/pcparts/cpu.jpg"}) { ...eyecatchImg },
    memory: file(relativePath: { eq: "cover/pcparts/memory.png"}) { ...eyecatchImg },
    ssd_size: file(relativePath: { eq: "cover/pcparts/ssd_size.PNG"}) { ...eyecatchImg },
    office: file(relativePath: { eq: "cover/pcparts/office.PNG"}) { ...eyecatchImg },
    grabo: file(relativePath: { eq: "cover/pcparts/grabo.png"}) { ...eyecatchImg },
    monitor: file(relativePath: { eq: "cover/pcparts/monitor.png"}) { ...eyecatchImg },
    drive: file(relativePath: { eq: "cover/pcparts/drive.jpg"}) { ...eyecatchImg },
    virus: file(relativePath: { eq: "cover/pcparts/virus.png"}) { ...eyecatchImg },
    hddssd: file(relativePath: { eq: "cover/pcparts/hdd-ssd.jpg"}) { ...eyecatchImg },
    keyboard: file(relativePath: { eq: "cover/pcparts/keyboard.png"}) { ...eyecatchImg },
    defaultthumb: file(relativePath: { eq: "cover/defaultthumb.png"}) { ...eyecatchImg },
  }
`
